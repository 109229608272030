//
//
//
//
//
//
//

import MainSection from './components/MainSection'
import PageHeader from './components/Header'
import PageFooter from './components/Footer'
export default {
    name: 'Layout',
    components: {MainSection, PageHeader, PageFooter},
    created() {
		this.getuniacid()
        this.getUserInfo()
		console.log('layout');
    },
    methods: {
		//获取 网站配置信息 
		async getuniacid(){
			// this.uniacid= 4 ;
			 var url = window.location.href;
			 var dz_url = url.split("#")[0];
			 var www_dz_url = dz_url.split("/")[2];
			//是否是二级域名 
			var centerdomain= www_dz_url.split(".");
			let res
			console.log('centerdomain:',centerdomain);
			if(centerdomain[1]=='khshop'){
				  res = await this.$http.get('app.layout/weblist',{centerdomain:centerdomain[0]})
				console.log('websiteinfo:',res);
			}else{
				  res = await this.$http.get('app.layout/weblist',{siteroot:www_dz_url})
				console.log('websiteinfo:',res);
			}
			
			
			 if(res.length>0){
				 this.uniacid = res[0].ucid
				 window.localStorage.setItem('webinfo', JSON.stringify(res[0]));
				 window.localStorage.setItem('uniacid', res[0].ucid);
			 }
			 var ss = window.localStorage.getItem('webinfo');
			 var ssarray = JSON.parse(ss)
			 console.log('ss',ss);
			 console.log('ssarray.ucid',ssarray['ucid']);
		},
		
        async getUserInfo(e) {
            const user_id = window.localStorage.getItem('user_id') || ''
            let res = null
            if(user_id){
                res = await this.$http.get('/user/profile',{user_id})
            }
            user_id && res.code == 0 && this.$store.commit('saveUserInfo',res.data)
        },
		
		
    }
}
